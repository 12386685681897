<script setup lang="ts">
import IconMilicoin from '@/shared/assets/icons/IconMilicoin.svg'
import { ButtonGreen } from '@/shared/ui/button'

defineProps({
  coins: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['buy'])
</script>

<template>
  <div class="min-w-160">
    <div class="text-p-semibold mb-4">
      {{ $t('header.your_balance') }}
    </div>
    <div class="rounded-14 bg-white p-8">
      <div class="flex items-center justify-center">
        <IconMilicoin class="mb-2 mr-2" />
        <div class="text-h1.5-semibold text-center text-black">{{ coins }}</div>
      </div>
      <div class="text-h5-medium mb-8 text-center text-gray-600">
        {{ $t('header.coins_left') }}
      </div>
      <ButtonGreen class="text-h5-medium !w-full px-8" :size="'32'" @click="emit('buy')">
        {{ $t('shared.buy_coins') }}
      </ButtonGreen>
    </div>
  </div>
</template>
